<template>
    <div class="bank-product-area__card section-card">
        <div class="bank-product-area__card--header section-card-header">
            <div class="section-card-title">
                <img :src="getSectionTexts.icon" alt="">
                <h2>{{ getSectionTexts.title }}</h2>
                <span>{{ scoreData.length }}</span>
            </div>
            <div class="section-card-actions">
                <router-link
                    v-if="!hideViewAll && !isEmpty"
                    :to="getSectionTexts.viewAllRoute"
                >{{ $t("View All") }}
                </router-link>

                <DashboardSortSelect
                    v-model="customersSorting"
                    :disabled="isEmpty"
                    @onSortingChange="handleSortingChange"
                />
            </div>
        </div>

        <div class="bank-product-area__card--body">
            <BankProductAreaList
                v-if="!isEmpty"
                :data-list="scoreData"
                :has-image="listItemHasIcon"
                @onItemClick="handleItemClick"
            />
            <DashboardEmptyState
                v-else
                :icon="getSectionTexts.emptyIcon"
                :title="getSectionTexts.emptyTitle"
                :text="getSectionTexts.emptyText"
                :button-text="hideFooter ? getSectionTexts.emptyButtonText : ''"
                button-link="/business/settings/user-settings/overview"
                button-icon="/media/buying/icons/plus.svg"
                class-name="mb-10"
            />
        </div>

        <div class="bank-product-area__card--footer" v-if="!hideFooter">
            <router-link to="/business/settings/user-settings/overview" class="footer-btn">
        <span>
          <img :src="getSectionTexts.footerButtonIcon" alt="">
          {{ getSectionTexts.footerButtonText }}
        </span>
                <inline-svg src="/media/buying/icons/arrow-right-dark.svg" width="10" height="17" />
            </router-link>
        </div>
    </div>
</template>

<script>
import { scoresSortingLevels } from "@/buying-teams/pages/bank/dashboard/dashboardData";
import DashboardSortSelect from "@/buying-teams/shared-components/inputs/DashboardSortSelect";
import BankProductAreaList from "@/buying-teams/pages/business/dashboard/components/overview/BankProductAreaList";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";
import { BankCommonListEnum } from "@/store/enums/BankCommonListEnum";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";

export default {
    name: "DashboardCommonList",
    components: {
        DashboardEmptyState,
        DashboardSortSelect,
        BankProductAreaList
    },
    props: {
        title: String,
        icon: String,
        scoreData: Array,
        footerButtonText: String,
        footerButtonIcon: String,
        hideViewAll: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        listItemHasIcon: {
            type: Boolean,
            default: false
        },
        pageType: {
            type: Number
        },
        dataContainer: Object,
        filterData: Object,
        platformType: {
            type: String,
            default: PlatformTypeEnum.BUSINESS
        }
    },
    data() {
        return {
            customersSorting: "lowest_score"
        };
    },
    computed: {
        customersOptions() {
            return scoresSortingLevels;
        },
        isEmpty() {
            return !this.scoreData || !this.scoreData.length;
        },
        getSectionTexts() {
            switch (this.pageType) {
                case BusinessCommonListEnum.PRODUCT_AREA:
                case BankCommonListEnum.PRODUCT_AREA:
                    return {
                        title: this.$t("Product Areas"),
                        icon: "/media/buying/icons/product-areas-icon.svg",
                        footerButtonIcon: "/media/buying/icons/writing-icon.svg",
                        footerButtonText: this.$t("Manage Product Areas"),
                        emptyIcon: "/media/buying/icons/speedometer-icon.svg",
                        emptyTitle: this.$t("No Active Product Area Found"),
                        emptyText: this.$t("Your company don't have any product areas reviewed for this bank. Add product areas and start a feedback session to get insights."),
                        emptyButtonText: this.$t("Add Product Area"),
                        viewAllRoute: "/business/dashboard/product-areas"
                    };
                case BusinessCommonListEnum.COUNTRIES:
                case BankCommonListEnum.COUNTRIES:
                    return {
                        title: this.$t("Countries"),
                        icon: "/media/buying/icons/map-black-icon.svg",
                        footerButtonIcon: "/media/buying/icons/bank-pic-placeholder.svg",
                        footerButtonText: this.$t("Manage Countries"),
                        emptyIcon: "/media/buying/icons/empty-state-country.svg",
                        emptyTitle: this.$t("No Country Found"),
                        emptyText: this.$t("Your company don't have any countries reviewed for this bank. Add countries and start a feedback session to get insights."),
                        emptyButtonText: this.$t("Add Country"),
                        viewAllRoute: "/business/dashboard/countries"
                    };
                case BusinessCommonListEnum.BANKS:
                    return {
                        title: this.$t("Banks"),
                        icon: "/media/buying/icons/bank-icon.svg",
                        footerButtonIcon: "/media/buying/icons/bank-pic-placeholder.svg",
                        footerButtonText: this.$t("Manage Banks"),
                        emptyIcon: "/media/buying/icons/empty-state-bank.svg",
                        emptyTitle: this.$t("No Banks Found"),
                        emptyText: this.$t("There are no data for this product area. Add bank(s) and create feedback session for this product area to get insights."),
                        emptyButtonText: this.$t("Add Bank"),
                        viewAllRoute: "/business/dashboard/banks"
                    };
                default:
                    return {};
            }
        },
        businessBaseFilterDefaultState() {
            return store.getters.businessBaseFilterDefaultState;
        },
    },
    methods: {
        handleItemClick(item) {
            if (this.platformType === PlatformTypeEnum.BUSINESS) {
                const time_period = this.filterData.time_period || this.businessBaseFilterDefaultState.time_period;
                const bank_ids = this.filterData.bank_ids || this.businessBaseFilterDefaultState.bank_ids || [];
                const countries = this.filterData.countries || this.businessBaseFilterDefaultState.countries || [];
                const product_areas = this.filterData.product_areas || this.businessBaseFilterDefaultState.product_areas || [];

                switch (item.type) {
                    case BusinessCommonListEnum.PRODUCT_AREA:
                        store.commit("SAVE_BUSINESS_SINGLE_PRODUCT_AREA_DASHBOARD_FILTER", {bank_ids, countries, time_period});
                        this.$router.push(`/business/dashboard/product-areas/${item.title}`);
                        break;
                    case BusinessCommonListEnum.COUNTRIES:
                        store.commit("SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER", {bank_ids, product_areas, time_period});
                        this.$router.push(`/business/dashboard/countries/${item.title}`);
                        break;
                    case BusinessCommonListEnum.BANKS:
                        store.commit("SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER", {product_areas, countries, time_period});
                        this.$router.push(`/business/dashboard/banks/${item.id}`);
                        break;
                    default:
                        return;
                }
            } else {
                switch (item.type) {
                    case BankCommonListEnum.PRODUCT_AREA:
                        this.$router.push(`/bank/product-areas/${item.title}`);
                        break;
                    default:
                        diagLog('Undefined item => ', item);
                        break;
                        // TODO filter switch and redirect
                }
            }
        },
        handleSortingChange(sorting) {
            this.dataContainer.handleSorting(this.pageType, sorting);
        }
    }
};
</script>

<style scoped lang="scss">
.bank-product-area {
    width: 50%;

    &__card {
        overflow: hidden;
        height: 100%;

        &--header {

        }

        &--body {
            height: calc(100% - 129px);
        }

        &--footer {
            padding: 0 4px 4px;

            a {
                background: rgba(67, 91, 244, 0.05);
                border-radius: 14px;
                border: none;
                width: 100%;
                height: 63px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 25px 10px 20px;
                transition: .3s ease-in-out;

                span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 13px;
                    color: #435BF4;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 12px;
                    }
                }

                svg {
                    path {
                        fill: #435BF4;
                    }
                }

                &:hover {
                    background: rgb(67 91 244 / 13%);
                }
            }
        }
    }
}
</style>
