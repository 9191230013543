<template>
    <div class="select-container">
        <el-select
            v-model="modelValue"
            :disabled="disabled"
            class="dashboard-sort-select"
            :popper-append-to-body="false"
            placement="bottom-end"
            @change="handleChangeSelect"
        >
            <template #prefix>
                <img src="/media/buying/icons/sort-icon.svg" width="23" alt="">
            </template>
            <el-option
                v-for="(item, index) in customersOptions"
                :key="index"
                :label="$t(item.label)"
                :value="item.key"
            />
        </el-select>
        <div
            v-if="showBadge"
            class="select-container_badge ">
        </div>
    </div>

</template>

<script>
import { scoresSortingLevels } from "@/buying-teams/pages/bank/dashboard/dashboardData";

export default {
    name: "DashboardSortSelect",
    props: {
        modelValue: String,
        disabled: {
            type: Boolean,
            default: false
        },
        sortOptions: {
            type: Array,
            default: null
        },
        showBadge: {
            type: Boolean,
            default: false
        }
    },
    emits: ["onSortingChange", "update:modelValue"],
    computed: {
        customersOptions() {
            return this.sortOptions ? this.sortOptions : scoresSortingLevels;
        }
    },
    methods: {
        handleChangeSelect() {
            this.$emit("onSortingChange", this.modelValue);
            this.$emit("update:modelValue", this.modelValue);
        }
    }
};
</script>

<style lang="scss">
.dashboard-sort-select {
    cursor: pointer;

    .el-input__prefix {
    }

    .el-input__inner {
        padding: 0;
        width: 28px;
        height: 28px;
        color: transparent;
        background: transparent;
    }

    .el-input__suffix {
        display: none;
    }

    .el-input.is-disabled {
        .el-input__inner {
            background-color: transparent;
        }

        .el-input__prefix-inner {
            img {
                filter: grayscale(2);
                opacity: 0.6;
            }
        }
    }

    .el-select-dropdown__item {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #515151;
        display: flex;
        align-items: center;

        &.selected {
            background: #435BF4;
            color: #FFFFFF;
            font-weight: 500;

            &:after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url("/media/buying/icons/check-icon.svg");
                width: 8px;
                height: 6px;
                filter: invert(1);
            }
        }
    }
}
.select-container {
    position: relative;

    &_badge {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 1px;
        left: 17px;
        border-radius: 50%;
        background: #E22D21;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
    }
}
</style>
