<template>
  <transition-group tag="div" class="list-section" name="group-transition">
    <div v-for="item in dataList" :key="item.title">
      <div
        class="list-section__item"
        :class="{ 'has-image': item.iconPath }"
        @click="triggerRedirect(item)"
      >
        <div class="list-section__item--img" v-if="item.iconPath">
          <img :src="item.iconPath" alt="">
        </div>
        <div class="list-section__item--data">
          <div class="list-title">{{ item.title }}</div>
          <div class="list-stats">
            <div class="list-stats-item stats-score" :class="getStatusClass(item.stats.score)">
              <div class="list-stats-label"></div>
              <span>{{ $t('Score') }}</span>
              <p>{{ numberDecimalsFormat(item.stats.score) }}</p>
            </div>
            <div class="list-stats-item stats-imp">
              <div class="list-stats-label"></div>
              <span>{{ $t('Imp') }}</span>
              <p>{{ numberDecimalsFormat(item.stats.importance) }}</p>
            </div>
            <div class="list-stats-item stats-gap" v-if="!hideGap" :class="getGapStatusClass(item.stats.gap)">
              <div class="list-stats-label"></div>
              <span>{{ $t('Gap') }}</span>
              <p>{{ numberDecimalsFormat(item.stats.gap) }}</p>
            </div>
          </div>
        </div>

        <div class="list-section__item--arrow" v-if="!hideArrow">
          <inline-svg src="/media/buying/icons/arrow-black.svg" />
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import {Constants} from "@/core/config/constants";
import { getStatusClass, getGapStatusClass, numberDecimalsFormat } from "@/core/helpers/GlobalHelper";

export default {
  name: "BankProductAreaList",
  props: {
    dataList: Array,
    hideGap: {
      type: Boolean,
      default: false
    },
    hideArrow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onItemClick'],
  data() {
    return {
      Constants,
      getStatusClass,
      getGapStatusClass,
      numberDecimalsFormat
    }
  },
  methods: {
    triggerRedirect(item) {
      this.$emit('onItemClick', item);
    }
  }
}
</script>

<style scoped lang="scss">
.list-section {
  max-height: 419px;
  overflow-y: auto;
  &__item {
    min-height: 84px;
    padding: 16px 18px 16px 0;
    display: flex;
    padding-left: 33px;
    position: relative;
    cursor: pointer;
    transition: .1s ease-in-out;
    &:hover {
      background: #f5f5f5;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.06);
    }
    &:last-child:before {
      display: none;
    }
    &--img {
      margin-right: 20px;
      img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    &--data {
      width: calc(100% - 62px);
    }
    &--arrow {
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    }
    .list-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: #515151;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .list-stats {
      display: flex;
      align-items: center;
      gap: 16px;
      .list-stats-item {
        display: flex;
        align-items: center;
        .list-stats-label {
          width: 8.3px;
          height: 8.3px;
          border-radius: 50%;
          background: #ECECEC;
          margin-right: 7px;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          opacity: 0.5;
          margin-right: 4px;

        }
        p {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
          color: #313131;
        }

        &.stats-score {
          &.status-danger {
            .list-stats-label {
              background: #E22D21;
            }
          }
          &.status-warning {
            .list-stats-label {
              background: #FBDC6C;
            }
          }
          &.status-success {
            .list-stats-label {
              background: #2BC490;
            }
          }
        }

        &.stats-imp {
          .list-stats-label {
            background: #CED4F8;
          }
          p {
            font-weight: 400;
          }
        }

        &.stats-gap {
          .list-stats-label {
            position: relative;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 6px;
            }
          }
          &.status-success {
            .list-stats-label {
              background-color: #DBF7ED;

              &:after {
                background-image: url("/media/buying/icons/gap-value-icon.svg");
                filter: invert(66%) sepia(12%) saturate(2047%) hue-rotate(108deg) brightness(93%) contrast(97%);
              }
            }
          }
          &.status-danger {
            .list-stats-label {
              background-color: #FBDDDB;

              &:after {
                background-image: url("/media/buying/icons/gap-value-icon.svg");
                filter: invert(20%) sepia(88%) saturate(3117%) hue-rotate(353deg) brightness(96%) contrast(86%);
              }
            }
          }
        }
      }
    }

    &.has-image {
      padding-left: 24px;
      &:before {
        left: 86px;
      }
    }
  }

  @media (max-width: 600px) {
    &__item {
      padding-left: 20px;
      &--arrow {
        right: 15px;
      }
    }
  }
}
</style>
