import { Constants } from "@/core/config/constants";
import { BusinessCommonListEnum } from "@/store/enums/BusinessCommonListEnum";

export class BusinessCommonListItem {
    type: BusinessCommonListEnum
    id: number;
    title: string;
    iconPath: string | null;
    stats: {
        score: number,
        scores: number,
        importance: number,
        gap: number
    };

    constructor(obj: any) {
        this.type = obj.type;
        this.id = obj.id;
        this.title = obj.title;
        this.iconPath = this.getIconPath(obj);
        this.stats = this.getStats(obj);
    }

    getIconPath(obj: any): string | null {
        let iconPath = null as any;

        if (obj.type === BusinessCommonListEnum.BANKS) {
            iconPath = Constants.BANK_TMP_LOGO;
            if (obj.icon_path) {
                iconPath = obj.icon_path;
            } else if (obj.icon) {
                iconPath = obj.icon;
            }
        }

        return iconPath;
    }

    getStats(obj) {
        let res: any = {
            score: obj.stats && obj.stats.score ? parseFloat(obj.stats.score) : 0,
            scores: obj.stats && obj.stats.scores ? parseFloat(obj.stats.scores) : 0,
            importance: obj.stats && obj.stats.importance ? parseFloat(obj.stats.importance) : 0,
            gap: obj.stats && obj.stats.gap ? parseFloat(obj.stats.gap) : 0,
        };

        return res;
    }
}


